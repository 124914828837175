table {
  border-collapse: collapse;
  max-width: 100px;
  width: 100%;
  margin: 2rem auto;
}

table th,
table td {
  border: 1px solid black;
  padding: 8px;
}
table {
  margin-bottom: 200px;
}

/* Existing styles */
/* .container-panel {
  text-align: center;
  margin: 2rem auto;
} */

.part-form {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.form-input,
.form-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-select {
  cursor: pointer;
}

.form-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.part-table {
  border-collapse: collapse;
  max-width: 100px;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 200px;
}

.part-table th,
.part-table td {
  border: 1px solid black;
  padding: 8px;
}

.table-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.table-button:hover {
  background-color: #0056b3;
}

.panel-image {
  max-width: 80px;
  max-height: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Optional: Center align the form and table on larger screens */
@media screen and (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .part-form {
    max-width: 600px;
  }

  .part-table {
    max-width: 600px;
  }
}
