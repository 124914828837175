/* Container styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 200px;
}

/* Header styling */
.header {
  text-align: center;
  margin-bottom: 20px;
}

/* Cards container styling */
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

/* Individual part card styling */
.part-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px; /* Increased height for better appearance */
  margin: 5px;
  border-radius: 15px;
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative; /* Ensure stock status is positioned correctly */
}

/* Styling for in-stock cards */
.in-stock {
  border: 2px solid green;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.5); /* Green box shadow */
  
}
.stock{
  margin: 0;
}

/* Styling for out-of-stock cards */
.out-of-stock {
  border: 2px solid red;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); /* Red box shadow */
}

/* Hover effect for card */
.part-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

/* Image wrapper styling */
.card-image-wrapper {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

/* Card image styling */
.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

/* Card text styling */
.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out content vertically */
  align-items: center;
  padding: 10px;
  height: 30%;
  box-sizing: border-box;
}

/* Title styling */
.card-title {
  font-weight: bold;
  font-size: 18px;
  color: blue; /* Adjust color as needed */
  text-align: center;
  margin: 0;
}

/* Price styling */
.card-price {
  font-weight: bold;
  font-size: 16px;
  color: green; /* Adjust color as needed */
  text-align: center;
  margin: 5px 0;
}

/* Stock status styling */
.stock {
  position: absolute; /* Position at the bottom of the card */
  bottom: 1px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  
}

/* Text color for in-stock items */
.in-stock-text {
  color: white;
  background-color: green; /* Green background for in-stock text */
}

/* Text color for out-of-stock items */
.out-of-stock-text {
  color: white;
  background-color: red; /* Red background for out-of-stock text */
}

/* Search bar styling */
.search-bar {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

/* Styling for selected card */
.selected {
  transform: scale(1.1); /* Slightly larger scale for selected card */
  z-index: 1; /* Bring the selected card to the front */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); /* Enhanced shadow for emphasis */
}

/* Enlarged card styling (currently commented out) */
.enlarged-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  z-index: 999; /* Ensure it's on top of other elements */
}

/* Sale text styling (currently commented out) */
.sale {
  color: red;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

/* Model text styling (currently commented out) */
.model {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
