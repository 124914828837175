.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #040c18;
  color: red;
  text-align: center;
  height: 100px;
  flex-direction: row;
  /* font-size: 40px; */
  font-weight: 800;
  padding: 0 20px;
}

/* .logo-container {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin: 5px;
} */

.contact-info {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 40px;

  background-image: linear-gradient(
    -225deg,
    #fff 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );

  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.addres-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 50px;
  font-size: 40px;
}
.addres-container a {
  font-size: 40px;
}

.address {
  margin: 0;
  font-size: 30px;
  text-decoration: underline;
  color: red;
}

.footer p {
  margin: 0;
  /* font-size: 40px; */
}

.viber {
  width: 80px;
  height: 80px;
}

.whatsapp {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}
.contact-info-container {
  display: flex;
  flex-direction: row;
}

.addres-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .footer {
    height: 105px;
    /* padding: 20px; */
    flex-direction: column;
  }
  .contact-info-container {
    display: flex;
    flex-direction: row;
  }

  .contact-info {
    gap: 10px;
    font-size: 34px;
    text-align: center; /* Center text */
    margin-bottom: 1px;
  }

  .addres-container {
    align-items: center; /* Center text */

    /* margin-top: 10px; */
  }

  .address,
  .footer p {
    font-size: 15px; /* Adjust font size for mobile */
  }

  .viber {
    width: 50px;
    height: 50px;
  }

  .whatsapp {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1220px) {
  .footer {
    font-size: 35px;
    padding: 15px;
    flex-direction: column;
    height: auto;
  }

  .contact-info,
  .addres-container {
    font-size: 18px; /* Adjust font size for medium screens */
    gap: 15px;
    align-items: center;
    text-align: center;
  }

  .address,
  .footer p {
    font-size: 30px; /* Adjust font size for medium screens */
  }

  .viber {
    width: 60px;
    height: 60px;
  }

  .whatsapp {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1221px) and (max-width: 1340px) {
  .footer {
    font-size: 30px;
    padding: 15px;
    flex-direction: column;
    height: auto;
  }

  .contact-info,
  .addres-container {
    font-size: 18px; /* Adjust font size for larger screens */
    gap: 15px;
    align-items: center;
    text-align: center;
  }

  .address,
  .footer p {
    font-size: 25px; /* Adjust font size for larger screens */
  }

  .viber {
    width: 60px;
    height: 60px;
  }

  .whatsapp {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }
}
