.brand_container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: blue;
  margin-bottom: 300px;
}

a {
  text-decoration: none;
  color: blue;
}

.brand_container h1 {
  margin-bottom: 80px;
  color: blue;
}

.cards_container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to the next line */
  align-items: flex-start; /* Align cards to the start of the cross-axis (top) */
  justify-content: center; /* Center cards along the main axis */
  gap: 20px; /* Add spacing between cards horizontally and vertically */
}

.brand_card {
  width: 400px; /* Set a fixed width for the cards */
  height: 300px; /* Set a fixed height for the cards */
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 800;
}

.brand_card img {
  object-fit: cover; /* Use cover instead of fill to maintain aspect ratio */
  width: 100%;
  height: 80%;
  border-top-left-radius: 30px; /* Add rounded corners to the image */
  border-top-right-radius: 30px;
}

@media screen and (max-width: 600px) {
  .cards_container {
    flex-direction: column;
    height: auto;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .brand_container h1 {
    text-align: center;
  }

  .brand_card {
    width: 100%;
    height: auto;
    margin: 10px 0;
    font-size: 16px;
    border-radius: 15px;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text content */
  }

  .brand_card img {
    height: 60%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%; /* Ensure image takes full width of the card */
  }
}
