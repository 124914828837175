body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main-page {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}
.header_text {
  margin-top: 20px;
  flex: 1; /* Allow text to take up remaining space */
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  height: 60px;
  color: blue;
  font-size: 30px;
}

.main {
  display: flex;
  margin-top: 70px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
  min-height: calc(100vh - 60px - 300px);
  padding-bottom: 300px;
}

.card {
  margin: 10px;
  width: 350px;
  height: 300px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.logo {
  width: 20px; /* Adjust width as needed */
  height: auto;
  margin-top: 10px;

  margin-left: 30px;
}

.toyota {
  width: 150%;
  height: 150%;
}

/* .toyota,
.kia,
.hyundai,
.lexus,
.tesla {
  width: 80%;
  height: auto;
  margin: auto;
} */

@media screen and (max-width: 600px) {
  .main-page {
    align-items: stretch;
  }

  .main {
    margin-top: 30px;
    margin-bottom: 200px;
    flex-direction: column;
    align-items: stretch;
    min-height: calc(100vh - 60px);
    padding-bottom: 0;
    width: auto;
  }
  .card {
    width: 200px;
    height: 150px;
  }
  .toyota,
  .kia,
  .hyundai,
  .lexus,
  .tesla {
    width: 80%;
    height: auto;
    margin: auto;
  }

  .card {
    margin: 10px 0;
    width: 100%;
    height: 200px;
  }

  .header {
    /* margin-top: 50px; */
    height: 50px;
    font-size: 20px;
  }
  /* */
  .header_text {
    font-size: 20px; /* Adjust font size for mobile */
  }

  .header_text {
    margin-top: 30px; /* Adjust margin-top for mobile */
    font-size: 30px; /* Adjust font size for mobile */
    text-align: center;
  }
}
@media screen and (max-width: 601px) {
  .logo {
    width: 140px;
  }
  .header_text {
    margin-top: 40px;
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .logo {
    width: 170px; /* Adjust width for medium screens */
  }

  .header_text {
    font-size: 40px; /* Adjust font size for medium screens */
    margin-top: 60px;
  }
}

@media screen and (min-width: 1201px) {
  .logo {
    width: 200px; /* Adjust width for larger screens */
  }

  .header_text {
    font-size: 60px; /* Adjust font size for larger screens */
    margin-top: 40px;
  }
}
